<script setup lang="ts">
const props = defineProps({
  inactiveDelaySeconds: {
    type: Number,
    default: 60,
  },
  notificationDelaySeconds: {
    type: Number,
    default: 60,
  },
})

const emit = defineEmits(['expire'])

const EVENTS = ['touchstart', 'mousemove', 'click', 'keydown']
const lastEventTimestamp = ref(Date.now())
const currentTimestamp = ref(Date.now())
let timer: ReturnType<typeof setInterval>

function updateLastEvent() {
  lastEventTimestamp.value = Date.now()
}
function restartInteractionTracking() {
  updateLastEvent()
  EVENTS.forEach(eventName => window.addEventListener(eventName, updateLastEvent))
  timer = setInterval(() => {
    currentTimestamp.value = Date.now()
  }, 1000)
}
function stopInteractionTracking() {
  EVENTS.forEach(eventName => window.removeEventListener(eventName, updateLastEvent))
  if (timer) {
    clearInterval(timer)
  }
}
const isInactivityOverlayShown = computed(
  () => (currentTimestamp.value - lastEventTimestamp.value) / 1000 > props.inactiveDelaySeconds
)

onMounted(() => {
  restartInteractionTracking()
})
onUnmounted(() => {
  stopInteractionTracking()
})
</script>

<template>
  <LayoutInactivityOverlay
    v-if="isInactivityOverlayShown"
    :notification-delay-seconds="props.notificationDelaySeconds"
    @mounted="stopInteractionTracking"
    @cancel="restartInteractionTracking"
    @expire="emit('expire')"
  />
</template>
