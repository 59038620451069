<script setup lang="ts">
const props = defineProps({
  notificationDelaySeconds: {
    type: Number,
    default: 60,
  },
})

const emit = defineEmits(['mounted', 'expire', 'cancel'])

let timer: ReturnType<typeof setInterval>
const renderedTimestamp = ref(Date.now())
const currentTimestamp = ref(Date.now())

const countdownSeconds = computed(() => {
  const runningSeconds = Math.floor((currentTimestamp.value - renderedTimestamp.value) / 1000)
  const countdownSeconds = props.notificationDelaySeconds - runningSeconds
  return countdownSeconds < 0 ? 0 : countdownSeconds
})

function startCountdown() {
  timer = setInterval(() => {
    currentTimestamp.value = Date.now()
    if (countdownSeconds.value === 0) {
      clearInterval(timer)
      emit('expire')
    }
  }, 1000)
}

onMounted(() => {
  emit('mounted')
  startCountdown()
})
onUnmounted(() => {
  clearInterval(timer)
})
</script>

<template>
  <div class="InactivityOverlay">
    <div class="h-full flex flex-col justify-center items-center">
      <span class="text-white text-xl">Die Sitzung wird in {{ countdownSeconds }} Sekunden beendet</span>
      <base-button class="mt-4 w-80" size="large" type="primary" @click="$emit('cancel')">
        Abbrechen
      </base-button>
    </div>
  </div>
</template>

<style scoped>
.InactivityOverlay {
  @apply fixed h-full z-10 top-0 left-0 w-full backdrop-blur;
  background-color: rgba(119, 119, 119, 0.7);
}
</style>
