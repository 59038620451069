<script setup lang="ts">
import { format } from 'date-fns'

import { de } from 'date-fns/locale'
import type { UserSessionType } from '~/server/database/entities/UserSession'

defineProps({
  session: {
    type: Object as PropType<UserSessionType>,
    default: () => ({}),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

defineEmits<{
  (e: 'logout'): void
}>()

const currentTime = ref(new Date())
let timeInterval: ReturnType<typeof setInterval>
onMounted(() => {
  timeInterval = setInterval(() => {
    currentTime.value = new Date()
  }, 1000)
})
onUnmounted(() => {
  clearInterval(timeInterval)
})

const formattedCurrentTime = computed(() =>
  format(currentTime.value, 'dd MMM yyyy HH:mm:ss', { locale: de }).replace('.', '')
)
</script>

<template>
  <div class="py-2 px-3 grid grid-cols-3 items-center bg-slate-200 text-slate-500 font-bold text-lg">
    <div data-testid="currentTime">
      {{ formattedCurrentTime }}
    </div>
    <div v-if="session.location" class="col-span-2 flex items-center">
      <div class="w-full flex justify-center">
        <nuxt-link to="/flow?id=admin">
          <base-button type="link">
            Service Point konfigurieren
          </base-button>
        </nuxt-link>
      </div>
      <div class="Session flex justify-end w-full">
        <span class="font-bold capitalize">{{ session.location.title }}</span>
        <BaseButton :is-loading="isLoading" type="link" icon="leave" @click="$emit('logout')" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.Session {
  @apply flex items-center gap-x-2;
}
</style>
