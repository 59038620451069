<script setup lang="ts">
const isSessionChecked = ref(false)

const router = useRouter()
const isMainPage = computed(() => router.currentRoute.value.fullPath === '/')
const isConfigurationPage = computed(() => router.currentRoute.value.query.id === 'configuration')

const inactiveDelaySeconds = import.meta.dev ? 60 * 60 : 60
const notificationDelaySeconds = 10

const { checkSession, session, isLoading, logout } = useAuthStore()
onBeforeMount(async () => {
  try {
    await checkSession()
  }
  finally {
    isSessionChecked.value = true
  }
})

function clickTile(id: string) {
  router.push(`/flow?id=${id}`)
}
</script>

<template>
  <div class="h-full relative overflow-hidden">
    <a-spin class="h-full" :spinning="!isSessionChecked">
      <div class="h-full flex flex-col">
        <div class="flex-grow">
          <LayoutWelcomeScreen v-if="!session.id" />
          <!-- disabled flows are temporarily added
            please check
            http://localhost:3000/flow?id=onboarding
            for testing the actual flows
          -->
          <FlowTiles v-else class="h-full w-full p-8" :flow-ids="['onboarding-disabled', 'offboarding', 'equipment', 'support', 'refund', 'help']" @click="clickTile" />
        </div>
        <LayoutAppStatus :session="session" :is-loading="isLoading" class="flex-shrink-0" @logout="logout" />
      </div>
      <Transition name="slide-fade">
        <div v-if="!isMainPage" class="h-full w-full top-0 left-0 absolute bg-gray-50">
          <slot v-if="session.id || isConfigurationPage" />
          <template v-else>
            <BaseTitle
              class="flex-grow-0 h-full"
              title="Bitte konfigurieren Sie zunächst den Servicepunkt, um Zugang zu erhalten"
            >
              <nuxt-link to="/">
                <base-button
                  icon="back"
                  type="primary"
                  class="h-12 w-80"
                  size="large"
                >
                  Zurück
                </base-button>
              </nuxt-link>
            </BaseTitle>
          </template>
        </div>
      </Transition>
      <LayoutInactivityCheck
        v-if="!isMainPage"
        :inactive-delay-seconds="inactiveDelaySeconds"
        :notification-delay-seconds="notificationDelaySeconds"
        @expire="$router.push('/')"
      />
    </a-spin>
  </div>
</template>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.5s ease-in-out;
}
.slide-fade-leave-active {
    transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(100%);
}
</style>
